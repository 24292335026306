import React from "react"

import { useInView } from "react-intersection-observer"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import { Container } from "decentraland-ui/dist/components/Container/Container"
import { Mobile, NotMobile } from "decentraland-ui/dist/components/Media/Media"
import { motion } from "framer-motion"

import { ContentfulAboutEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import { SectionViewedTrack } from "../../../modules/segment"

import "./LandingAbout.css"

const Wrapper = (props: any) => {
  return <span className="word-wrapper">{props.children}</span>
}

/**
 * Split text into words and letters
 * @param text
 */
const splitText = (text: string): string[][] => {
  //  Split each word of props.text into an array
  const splitWords = text.split(" ") || []

  // Create storage array
  const words: string[][] = []

  // Push each word into words array
  for (const [, item] of splitWords.entries()) {
    words.push(item.split(""))
  }

  // Add a space ("\u00A0") to the end of each word
  words.map((word) => {
    return word.push("\u00A0")
  })

  return words
}

export type LandingAboutProps = { about: ContentfulAboutEntriesProps }

export const LandingAbout = (props: LandingAboutProps) => {
  const { about } = props

  const [ref, isInView] = useInView({ threshold: 0.5, triggerOnce: true })

  const lines = splitText(about.title)
  const letterAnimation = {
    visible: {
      transition: {
        staggerChildren: 0.005,
      },
    },
  }

  const expo = {
    duration: 2,
    ease: (t: number) => {
      return t === 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t)
    },
  }

  const item = {
    hidden: {
      y: "200%",
      color: "rgba(255,255,255,0)",
      transition: expo,
    },
    visible: {
      y: 0,
      color: "#ffffff",
      transition: expo,
    },
  }

  return (
    <Container fluid className={"about"}>
      <SectionTrack sectionViewed={SectionViewedTrack.LandingAbout} />
      <NotMobile>
        <img
          alt="about"
          src={about.imageLandscape.url}
          className="about__image"
        />
      </NotMobile>
      <Mobile>
        <img
          alt="about"
          src={about.imagePortrait.url}
          className="about__image"
        />
      </Mobile>
      <motion.div
        className={"about-text"}
        ref={ref}
        initial="hidden"
        variants={letterAnimation}
        animate={isInView ? "visible" : "hidden"}
      >
        <h2>
          {lines.map((word: string[], index: number) => {
            return (
              <Wrapper key={index}>
                {lines[index].map((element: string, index: number) => {
                  return (
                    <span
                      style={{
                        overflow: "hidden",
                        display: "inline-block",
                      }}
                      key={index}
                    >
                      <motion.span
                        style={{ display: "inline-block" }}
                        variants={item}
                      >
                        {element}
                      </motion.span>
                    </span>
                  )
                })}
              </Wrapper>
            )
          })}
        </h2>
      </motion.div>
    </Container>
  )
}
