import React, { useCallback, useState } from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import SubTitle from "decentraland-gatsby/dist/components/Text/SubTitle"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"
import {
  Desktop,
  TabletAndBelow,
} from "decentraland-ui/dist/components/Media/Media"
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu"
import { MenuItemProps } from "semantic-ui-react/dist/commonjs/collections/Menu/MenuItem"

import { SectionViewedTrack } from "../../../modules/segment"
import EventsFeed from "../../Feed/EventsFeed"
import PlacesFeed from "../../Feed/PlacesFeed"

import "./LandingEvents&PlacesFeed.css"

export enum Feed {
  EVENTS = "events",
  PLACES = "places",
}

export const LandingEventsPlacesFeed = () => {
  const [feed, setFeed] = useState(Feed.EVENTS)
  const l = useFormatMessage()

  const [isSectionInView, setIsSectionInView] = useState(false)
  const handleClick = useCallback(
    (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      props: MenuItemProps
    ) => {
      setFeed(props.name as Feed)
    },
    []
  )

  return (
    <Container
      fluid
      className={TokenList.join([
        "events-places",
        isSectionInView && "events-places-visible ",
      ])}
    >
      <SectionTrack
        sectionViewed={SectionViewedTrack.LandingEventsPlacesFeed}
        onInView={() => setIsSectionInView(true)}
      />
      <SubTitle className="events-places__subtitle">
        {l("component.landing.events_places.subtitle")}
      </SubTitle>
      <Menu className="events-places__header">
        <Menu.Menu
          className={TokenList.join([
            "events-places__menu",
            `events-places__menu-${feed}`,
          ])}
        >
          <Menu.Item
            className="item__events-places"
            as={"div"}
            name={Feed.EVENTS}
            active={feed === Feed.EVENTS}
            onClick={handleClick}
          >
            <div>{l("component.landing.events_places.events.title")}</div>
          </Menu.Item>
          <Menu.Item
            className="item__events-places"
            as={"div"}
            name={Feed.PLACES}
            active={feed === Feed.PLACES}
            onClick={handleClick}
          >
            <div>{l("component.landing.events_places.places.title")}</div>
          </Menu.Item>
        </Menu.Menu>
        <Desktop>
          <Menu.Menu position="right" className="events-places__cta">
            <Menu.Item>
              <Button
                inverted
                href={
                  feed === Feed.EVENTS
                    ? l("component.landing.events_places.events.cta.link")
                    : l("component.landing.events_places.places.cta.link")
                }
                className="events-places__cta"
              >
                {feed === Feed.EVENTS
                  ? l("component.landing.events_places.events.cta.label")
                  : l("component.landing.events_places.places.cta.label")}
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Desktop>
      </Menu>
      {feed === Feed.EVENTS && <EventsFeed columns={2} />}
      {feed === Feed.PLACES && <PlacesFeed columns={2} />}

      <TabletAndBelow>
        <Button
          inverted
          href={
            feed === Feed.EVENTS
              ? l("component.landing.events_places.events.cta.link")
              : l("component.landing.events_places.places.cta.link")
          }
          className="events-places__cta"
        >
          {feed === Feed.EVENTS
            ? l("component.landing.events_places.events.cta.label")
            : l("component.landing.events_places.places.cta.label")}
        </Button>
      </TabletAndBelow>
    </Container>
  )
}
