import Time from "decentraland-gatsby/dist/utils/date/Time"
import { memo } from "radash"

import { EventAttributes } from "../@types/dcl/events"
import { getAttendees } from "./attendees"
import { getUsersInPosition } from "./usersInPosition"

export const fetchEvents = memo(
  async () => {
    const response = await fetch(
      `https://events.decentraland.org/api/events?list=active&limit=20`
    )
    const result = await response.json<{
      data: EventAttributes[]
      ok: string
    }>()

    const events = result.data

    const eventsWithAgreggated = await Promise.all(
      events.map(async (event) =>
        event.live
          ? {
              ...event,
              user_count: await getUsersInPosition(event.position.join(",")),
              attendees: [],
            }
          : {
              ...event,
              attendees: await getAttendees(event),
              user_count: 0,
            }
      )
    )
    eventsWithAgreggated.sort(
      (a, b) =>
        Number(b.live) - Number(a.live) ||
        b.user_count - a.user_count ||
        b.total_attendees - a.total_attendees
    )

    return eventsWithAgreggated
  },
  { ttl: Time.Minute * 10 }
)
