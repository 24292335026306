import React from "react"

import { useInView } from "react-intersection-observer"

import ImgFixed from "decentraland-gatsby/dist/components/Image/ImgFixed"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { useTabletAndBelowMediaQuery } from "decentraland-ui/dist/components/Media/Media"

import "./Card.css"

export type CardProps = {
  image?: string | null
  href?: string
  className?: string
  children?: React.ReactNode
}

export const Card = (props: CardProps) => {
  const { href, className, children, image } = props

  const isTabletAndBelow = useTabletAndBelowMediaQuery()
  const [cardInView, cardIsInView] = useInView({ threshold: 0.5 })
  return (
    <a
      ref={cardInView}
      href={href || ""}
      className={TokenList.join([
        "card",
        cardIsInView && "active",
        isTabletAndBelow && "card-tablet-and-below",
        className,
      ])}
    >
      <div className="card__container">
        <div className="card__background" />
        {image && (
          <ImgFixed
            className="card__image"
            dimension="square"
            src={image}
            background="black"
          />
        )}
        {children}
      </div>
    </a>
  )
}
