/* eslint-disable import/order */
import Link from "decentraland-gatsby/dist/plugins/intl/Link"
import { SegmentEvent } from "../../modules/segment"
import React from "react"
import { Button } from "decentraland-ui/dist/components/Button/Button"

import icon from "../../images/2023/icon_start.svg"

import "./LandingStartButton.css"

export const LandingWebButton = (props: any) => {
  return (
    <Button
      as={Link}
      inverted
      data-event={SegmentEvent.ExploreOnWeb}
      data-place="hero"
      href={props.href}
      onClick={props.onClick}
      className={"button-start"}
    >
      <img src={icon} alt={"start"} />
      <div className={"button-start__cta"}>
        {props.cta}
        {props.ctaSecondary && <div>{props.ctaSecondary}</div>}
      </div>
    </Button>
  )
}
