import React, { useEffect, useState } from "react"

import { useInView } from "react-intersection-observer"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Desktop } from "decentraland-ui/dist/components/Media/Media"
import { motion } from "framer-motion"

import { ContentfulMissionsEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import createCharacter from "../../../images/2023/create.png"
import sellCharacter from "../../../images/2023/explore.png"
import influenceCharacter from "../../../images/2023/influence.png"
import createGradient from "../../../images/2023/webp/create_gradient.webp"
import influenceGradient from "../../../images/2023/webp/influence_gradient.webp"
import shadow from "../../../images/2023/webp/ring_shadow.webp"
import sellGradient from "../../../images/2023/webp/sell_gradient.webp"
import { SectionViewedTrack } from "../../../modules/segment"
import MissionsImageCreate from "./MissionsImageCreate"
import MissionsImageSell from "./MissionsImageSell"
import MissionsInfluence from "./MissionsImageTrade"

import "./LandingMissions.css"
import "./LandingMissionsImages.css"

export enum MissionType {
  CREATE = "create",
  EXPERIENCE = "experience",
  INFLUENCE = "influence",

  NONE = "none",
}

export type MissionsProps = {
  id: string
  img_character: string
  img_gradient: string
  img_src_shadow: string
}

const missionTextVariants = {
  hidden: {
    opacity: 0,
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.85,
    },
  },
}

const Mission = (props: {
  onIsInView: () => void
  isActive?: boolean
  title?: string
  text?: string
  type: MissionType
  cta?: { label: string; link: string }
  ctaSecondary?: { label: string; link: string }
  index: number
  children: React.ReactNode
}) => {
  const [playsAnim, setPlaysAnim] = useState<boolean>(false)
  const [sectionInView, sectionIsInView] = useInView({
    threshold: 0.5,
  })

  const expo = {
    duration: 2,
    ease: (t: number) => {
      return t === 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t)
    },
  }

  useEffect(() => {
    if (sectionIsInView) {
      setPlaysAnim(true)
      props.onIsInView()
    }
  }, [sectionIsInView])

  const splitWords = props.title?.split(" ") || []
  const words: string[][] = []
  for (const [, item] of splitWords.entries()) {
    words.push(item.split(""))
  }

  return (
    <section ref={sectionInView} className="mission">
      <div className="mission__container">
        <div className="mission__index mission__index-desktop">
          {props.index}
        </div>

        <div className="mission__content">
          <div>
            <div className="mission__index  mission__index-tablet">
              {props.index}
            </div>

            <h2 className="mission__title mission__title-desktop">
              {words.map((word, index) => {
                return (
                  // Wrap each word in the Wrapper component
                  <span className="word-wrapper" key={index}>
                    {words[index].flat().map((element, index) => {
                      const delay = index * 0.01
                      const variant = {
                        hidden: {
                          y: "200%",
                          opacity: 0,
                          transition: {
                            ease: [0.455, 0.03, 0.515, 0.955],
                            duration: 0.85,
                            delay: delay,
                          },
                        },
                        visible: {
                          y: 0,
                          opacity: 1,
                          transition: expo,
                        },
                      }
                      return (
                        <span
                          style={{
                            overflow: "hidden",
                            display: "inline-block",
                          }}
                          key={"inner-" + index}
                        >
                          <motion.span
                            style={{ display: "inline-block" }}
                            variants={variant}
                            // initial={"hidden"}
                            animate={playsAnim ? "visible" : "hidden"}
                          >
                            {element}
                          </motion.span>
                        </span>
                      )
                    })}
                  </span>
                )
              })}
            </h2>
          </div>
          <div
            className={TokenList.join([
              "missions__container-img",
              "missions__container-img-mobile",
              props.isActive && "missions__container-img-visible",
            ])}
          >
            {props.children}
          </div>

          <motion.div
            // ref={sectionInView}
            initial="hidden"
            variants={missionTextVariants}
            animate={playsAnim ? "visible" : "hidden"}
          >
            <p className="mission__text">{props.text}</p>

            <div className="mission__actions">
              {props.cta && (
                <Button
                  size="large"
                  href={props.cta.link}
                  className="button-missions"
                >
                  {props.cta.label}
                </Button>
              )}
              {props.ctaSecondary && (
                <Button
                  size="large"
                  inverted
                  href={props.ctaSecondary.link}
                  className="button-missions"
                >
                  {props.ctaSecondary.label}
                </Button>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export type LandingMissionsProps = {
  missions: ContentfulMissionsEntriesProps
}

export const LandingMissions = (props: LandingMissionsProps) => {
  const { missions } = props
  const [activeSection, setActiveSection] = useState<MissionType>(
    MissionType.NONE
  )

  return (
    <>
      <div className="missions">
        <div className="missions__container">
          {missions.list.map((mission, index) => {
            const id = mission.id as MissionType

            const extraProps =
              (mission.buttonSecondaryLabel &&
                mission.buttonSecondaryLink && {
                  ctaSecondary: {
                    label: mission.buttonSecondaryLabel,
                    link: mission.buttonSecondaryLink,
                  },
                }) ||
              {}
            return (
              <>
                <SectionTrack
                  sectionViewed={SectionViewedTrack.LandingMissions}
                  extraData={{ title: mission.title }}
                />
                <Mission
                  text={mission.description.text}
                  title={mission.title}
                  cta={{
                    label: mission.buttonPrimaryLabel,
                    link: mission.buttonPrimaryLink,
                  }}
                  {...extraProps}
                  index={index + 1}
                  type={id}
                  key={id}
                  onIsInView={() => setActiveSection(id)}
                  isActive={activeSection === id}
                >
                  {id === MissionType.CREATE ? (
                    <MissionsImageCreate
                      id={"mobile"}
                      img_character={createCharacter}
                      img_gradient={createGradient}
                      img_src_shadow={shadow}
                    />
                  ) : id === MissionType.EXPERIENCE ? (
                    <MissionsImageSell
                      id={"mobile"}
                      img_character={sellCharacter}
                      img_gradient={sellGradient}
                      img_src_shadow={shadow}
                    />
                  ) : (
                    <MissionsInfluence
                      id={"mobile"}
                      img_character={influenceCharacter}
                      img_gradient={influenceGradient}
                      img_src_shadow={shadow}
                    />
                  )}{" "}
                </Mission>
              </>
            )
          })}
        </div>

        <Desktop>
          <div
            className={TokenList.join([
              "mission__images-desktop",
              "mission-desktop",
            ])}
          >
            <div>
              <div
                className={TokenList.join([
                  "missions__container-img",
                  activeSection === MissionType.CREATE &&
                    "missions__container-img-visible",
                ])}
              >
                <MissionsImageCreate
                  id={"desktop"}
                  img_character={createCharacter}
                  img_gradient={createGradient}
                  img_src_shadow={shadow}
                />
              </div>

              <div
                className={TokenList.join([
                  "missions__container-img",
                  activeSection === MissionType.EXPERIENCE &&
                    "missions__container-img-visible",
                ])}
              >
                <MissionsImageSell
                  id={"desktop"}
                  img_character={sellCharacter}
                  img_gradient={sellGradient}
                  img_src_shadow={shadow}
                />
              </div>
              <div
                className={TokenList.join([
                  "missions__container-img",
                  activeSection === MissionType.INFLUENCE &&
                    "missions__container-img-visible",
                ])}
              >
                <MissionsInfluence
                  id={"desktop"}
                  img_character={influenceCharacter}
                  img_gradient={influenceGradient}
                  img_src_shadow={shadow}
                />
              </div>
            </div>
          </div>
        </Desktop>
      </div>
    </>
  )
}
