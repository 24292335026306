/* eslint-disable import/order,sort-imports */
import React, { useMemo, useState } from "react"

import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import { getBlogCategories, getBlogposts } from "../../../modules/contentful"
import { createBlogPostList } from "../../../modules/formats"
import { useMobileMediaQuery } from "decentraland-ui/dist/components/Media/Media"
import { Container } from "decentraland-ui/dist/components/Container/Container"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import Title from "decentraland-gatsby/dist/components/Text/Title"

import { Carousel } from "../../Carousel/Carousel"
import { CarouselControlsMobile } from "../../Carousel/CarouselControlsMobile"

import { useDesktopSmallMediaQuery } from "../../Media/Media"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { SectionViewedTrack } from "../../../modules/segment"
import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"

import "./LandingBlogFeed.css"

const BlogCard = (props: { item: any }) => {
  const { item } = props

  return (
    <a href={item.url} className="Feed__Item blog-card">
      <div className="blog-card__image">
        <img src={item.image} />
      </div>
      <div className="blog-card__content">
        {item.publishedDate ? (
          <h2 className="blog-card__title">
            {item?.categoryTitle || ""} <span>{item.publishedDate}</span>
          </h2>
        ) : (
          <h2 className="blog-card__title">{item?.categoryTitle || ""}</h2>
        )}

        <p className="blog-card__description">{item.title || " "}</p>
      </div>
    </a>
  )
}
export const LandingBlogFeed = () => {
  const l = useFormatMessage()
  const [categories] = useAsyncMemo(getBlogCategories)

  const isMobile = useMobileMediaQuery()
  const isDesktopSmall = useDesktopSmallMediaQuery()
  const columns = isDesktopSmall ? (isMobile ? 1 : 2) : 4

  const carouselRef = React.useRef<any>(null)

  const [index, setIndex] = React.useState(0)

  const [isSectionInView, setIsSectionInView] = useState(false)

  //todo getting false positive here, state.laoded is true but posts is empty?
  const [relatedPosts, relatedPostsState] = useAsyncMemo(
    async () => {
      if (!categories) {
        return false
      }
      const blogpostContent = await getBlogposts({
        offset: 0,
        limit: 4,
      })

      return createBlogPostList(blogpostContent, categories)
    },
    [categories],
    {
      callWithTruthyDeps: true,
    }
  )

  const posts = useMemo<any[]>(() => {
    if (!relatedPosts || relatedPosts.length === 0) {
      return []
    }

    return relatedPosts
  }, [relatedPosts])

  return (
    <Container
      fluid
      className={"blog-feed " + (isSectionInView ? "blog-feed-visible " : "")}
    >
      <SectionTrack
        sectionViewed={SectionViewedTrack.LandingBlogFeed}
        onInView={() => setIsSectionInView(true)}
      />
      <div className={"blog-feed__container"}>
        <Title className={"blog-feed__title"}>
          {l("component.landing.blog.title")}
        </Title>
        <Carousel
          loading={relatedPostsState.loading}
          items={posts}
          component={BlogCard}
          loop={isMobile}
          ref={carouselRef}
          slidesPerView={columns}
          onIndexChange={(index) => {
            setIndex(index)
          }}
        />
        {columns < 4 && (
          <CarouselControlsMobile
            onPrev={() => carouselRef?.current?.previous()}
            onNext={() => carouselRef?.current?.next()}
            activeIndex={index}
            total={posts.length}
            columns={columns}
          />
        )}
        <Button inverted href={l("component.landing.blog.cta.link")}>
          {l("component.landing.blog.cta.label")}
        </Button>
      </div>
    </Container>
  )
}
