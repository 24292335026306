import React, { useState } from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import { Back } from "decentraland-ui/dist/components/Back/Back"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"
import {
  Desktop,
  TabletAndBelow,
  useMobileMediaQuery,
} from "decentraland-ui/dist/components/Media/Media"
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu"

import { SectionViewedTrack } from "../../../modules/segment"
import { Carousel } from "../../Carousel/Carousel"
import { CarouselControlsMobile } from "../../Carousel/CarouselControlsMobile"
import { useDesktopSmallMediaQuery } from "../../Media/Media"
import { MarketPlaceCard } from "./MarketPlaceCard"

import "./LandingMarketPlaceFeed.css"

const getMarketPlaceQuery = (ids: string[]) => `
query {
  items(where: { id_in: ${JSON.stringify(ids)} }) {
      id
      blockchainId
      creator
      price
      URI
      image
              totalSupply
        available
      metadata {
        id
        wearable {
          name
          description
          rarity
          category
          bodyShapes
        }
        itemType
        emote {
          name
          description
          rarity
          category
        }
      }
    }
  }
`

//TODO: are there types for this?
export type MarketPlaceAttributes = {
  id: string
  blockchainId: string
  creator: string
  price: string
  URI: string
  image: string

  totalSupply: string
  available: string
  metadata: {
    itemTtype: string
    wearable: {
      name: string
      description: string
      rarity: string
      category: string
      bodyShapes: string[]
    }
    emote: {
      name: string
      description: string
      rarity: string
      category: string
    } | null
  }
}

export type ItemResponse = {
  data: any[]
  total: number
}
const fetchMarketPlace = async (): Promise<MarketPlaceAttributes[]> => {
  let requestOptions: RequestInit = {
    method: "GET",
    redirect: "follow",
  }

  let res: any = await fetch(
    "https://nft-api.decentraland.org/v1/trendings?size=20",
    requestOptions
  )

  const marketplaceItems: ItemResponse = await res.json()

  const ids = marketplaceItems.data
    .map((item) => item.id)
    .filter((item) => !!item)

  const query = getMarketPlaceQuery(ids)
  const graphql = JSON.stringify({
    query,
    variables: {},
  })

  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: graphql,
    redirect: "follow",
  }

  res = await fetch(
    "https://subgraph.decentraland.org/collections-matic-mainnet",
    requestOptions
  )
  res = await res.json()

  const items: MarketPlaceAttributes[] = res.data.items
    //todo remove if no wearable?
    .filter((item: any) => !!item.metadata.wearable)

  return items
}

export const MarketPlace = () => {
  const l = useFormatMessage()
  const [items, state] = useAsyncMemo(fetchMarketPlace, [])

  const isMobile = useMobileMediaQuery()
  const isDesktopSmall = useDesktopSmallMediaQuery()
  const columns = isDesktopSmall ? (isMobile ? 1 : 2) : 4

  const [index, setIndex] = React.useState(0)
  const carouselRef = React.useRef<any>(null)

  const [isSectionInView, setIsSectionInView] = useState(false)
  return (
    <Container
      fluid
      className={
        "marketplace " + (isSectionInView ? "marketplace-visible" : "")
      }
    >
      <SectionTrack
        sectionViewed={SectionViewedTrack.LandingMarketplace}
        onInView={() => setIsSectionInView(true)}
      />
      <Menu>
        <Menu.Item>
          <Title>{l("component.landing.marketplace.title")}</Title>
        </Menu.Item>
        <Desktop>
          <Menu.Menu position="right">
            <Menu.Item className={"marketplace__cta-desktop"}>
              <Button
                inverted
                href={l("component.landing.marketplace.cta.link")}
              >
                {l("component.landing.marketplace.cta.label")}
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Back
                className={"back__left"}
                onClick={() => carouselRef?.current?.previous()}
              />
            </Menu.Item>
            <Menu.Item>
              <Back
                className={"back__right"}
                onClick={() => carouselRef?.current?.next()}
              />
            </Menu.Item>
          </Menu.Menu>
        </Desktop>
        <TabletAndBelow>
          <Menu.Item className={"marketplace__cta-mobile"}>
            <Button
              inverted
              size={"large"}
              className={"basic"}
              href={l("component.landing.marketplace.cta.link")}
            >
              {l("component.landing.marketplace.cta.label")}
            </Button>
          </Menu.Item>
        </TabletAndBelow>
      </Menu>
      <Carousel
        loading={state.loading}
        ref={carouselRef}
        items={items || []}
        component={MarketPlaceCard}
        slidesPerView={columns}
        onIndexChange={(index) => {
          setIndex(index)
        }}
        className={"carousel-landing marketplace__carousel"}
      />
      {columns < 4 && (
        <CarouselControlsMobile
          onPrev={() => carouselRef?.current?.previous()}
          onNext={() => carouselRef?.current?.next()}
          activeIndex={index}
          total={items?.length || []}
          columns={columns}
        />
      )}
    </Container>
  )
}
