import Catalyst, { Avatar } from "decentraland-gatsby/dist/utils/api/Catalyst"
import Time from "decentraland-gatsby/dist/utils/date/Time"
import { memo } from "radash"

import { EventAttributes } from "../@types/dcl/events"

const MAX_ATTENDEES = 3

export const getAttendees = memo(
  async (event: EventAttributes) => {
    const ids = event.latest_attendees.slice(0, MAX_ATTENDEES)

    let attendies: (Avatar | null)[] = []

    try {
      const result = await Catalyst.getInstance().getProfiles(ids)
      attendies = result || []
    } catch (e: any) {
      console.warn(e.message)
    }

    return Array.isArray(attendies) ? attendies : []
  },
  { ttl: Time.Hour }
)
