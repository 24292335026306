import React, { useRef, useState } from "react"

import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Back } from "decentraland-ui/dist/components/Back/Back"
import {
  Desktop,
  useTabletAndBelowMediaQuery,
} from "decentraland-ui/dist/components/Media/Media"
import { Autoplay, Swiper as SwiperClass } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import useDesktopTarget from "../../../hooks/useDesktopTarget"
import { LandingDownloadButton } from "../../Buttons/LandingDownloadButton"
import { LandingWebButton } from "../../Buttons/LandingWebButton"
import LandingHeroSlide, { LandingHeroSlideProps } from "./LandingHeroSlide"

import "./LandingHero.css"

export default React.memo(function LandingHero(props: {
  slides: LandingHeroSlideProps[]
}) {
  const [desktopTarget, loadingDesktopTarget] = useDesktopTarget()
  const isTabletAndBelow = useTabletAndBelowMediaQuery()
  const slides = props.slides

  const handleMainCTA = useTrackLinkContext(
    function (event: React.MouseEvent<HTMLButtonElement>) {
      if (isTabletAndBelow) {
        event.preventDefault()
        return
      }
    },
    [isTabletAndBelow]
  )

  const swiperRef = useRef<SwiperClass>()
  const [activeIndex, setActiveIndex] = useState(0)
  const onIndexChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex)
  }

  const goToIndex = (index: number) => {
    swiperRef.current?.slideTo(index)
  }

  return (
    <>
      <section
        className={TokenList.join(["landing-hero", "landing-hero-visible"])}
      >
        <Swiper
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper
          }}
          onActiveIndexChange={onIndexChange}
          modules={[Autoplay]}
          autoplay={{
            delay: 20000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <LandingHeroSlide key={index} {...slide}>
                {slide.id === "main" && (
                  <Desktop>
                    {!loadingDesktopTarget &&
                      !!desktopTarget &&
                      slide.buttonPrimaryLink &&
                      slide.buttonPrimaryLabel &&
                      slide.id === "main" && (
                        <LandingDownloadButton
                          href={desktopTarget}
                          onClick={handleMainCTA}
                          cta={slide.buttonPrimaryLabel}
                          ctaSecondary={slide.buttonPrimaryPreLabel}
                        />
                      )}
                    {!loadingDesktopTarget &&
                      !!desktopTarget &&
                      slide.buttonSecondaryLink &&
                      slide.buttonSecondaryLabel &&
                      slide.id === "main" && (
                        <LandingWebButton
                          cta={slide.buttonSecondaryLabel}
                          ctaSecondary={slide.buttonSecondaryPreLabel}
                          href={slide.buttonSecondaryLink}
                          onClick={handleMainCTA}
                        />
                      )}
                  </Desktop>
                )}
              </LandingHeroSlide>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="landing-hero__indicator">
          {slides.map((_, index: number) => (
            <span
              key={index}
              className={TokenList.join([activeIndex === index && "active"])}
              onClick={() => goToIndex(index)}
            ></span>
          ))}
        </div>
        <Desktop>
          <div className="landing-hero__controls">
            <Back
              className={TokenList.join([
                "back__left",
                activeIndex > 0 && "back-active",
              ])}
              onClick={() => {
                goToIndex(activeIndex - 1)
              }}
            />
            <Back
              className={TokenList.join([
                "back__right",
                activeIndex < slides.length - 1 && "back-active",
              ])}
              onClick={() => goToIndex(activeIndex + 1)}
            />
          </div>
        </Desktop>
      </section>
    </>
  )
})
