/* eslint-disable import/order */
import Link from "decentraland-gatsby/dist/plugins/intl/Link"
import { SegmentEvent } from "../../modules/segment"
import React from "react"

import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Logo } from "decentraland-ui/dist/components/Logo/Logo"

import "./LandingDownloadButton.css"

export const LandingDownloadButton = (props: any) => {
  return (
    <Button
      as={Link}
      primary
      data-event={SegmentEvent.Download}
      data-place="hero"
      href={props.href}
      onClick={props.onClick}
      className={"button-download"}
    >
      <Logo />
      <div className={"button-download__cta"}>
        {props.cta}
        <div>{props.ctaSecondary}</div>
      </div>
    </Button>
  )
}
