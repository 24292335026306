import React from "react"

import Title from "decentraland-gatsby/dist/components/Text/Title"
import Avatar from "decentraland-gatsby/dist/components/User/Avatar"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import Link from "decentraland-gatsby/dist/plugins/intl/Link"
import Time from "decentraland-gatsby/dist/utils/date/Time"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"

import { Card } from "../Card/Card"
import { Players } from "../Icon/Players"
import { EventFeedItems } from "./EventsFeed"

export const EventsFeedGroup = (props: { item: EventFeedItems[] }) => {
  const l = useFormatMessage()
  const group = props.item

  return (
    <div className={TokenList.join(["Feed__List", `events-feed__list`])}>
      {group.map((item, index) => {
        return (
          <Card
            className="event-card"
            image={item.image}
            href={item.url_events}
            key={item.id + " : " + index}
          >
            <>
              <div className="event-card__status">
                {item.live && (
                  <div className="status-live">
                    {l(
                      "component.landing.events_places.events_feed_group.live_now"
                    )}
                  </div>
                )}
                {item.live && item.user_count > 0 && (
                  <div
                    className={TokenList.join(["ui", "label", "user-count"])}
                  >
                    <Players noHover />
                    {item.user_count}
                  </div>
                )}
                {!item.live && (
                  <div className="status-date">
                    {" "}
                    {Time.utc(item.date).format("MMMM DD")}
                  </div>
                )}
              </div>
              <div
                className={TokenList.join([
                  "Feed__Item__Details",
                  `event-card__details`,
                ])}
              >
                <div>
                  <Title className="Feed__Item__Title">{item.name}</Title>

                  {item.user_name && (
                    <div className="feed-item__user-name">
                      {l(
                        "component.landing.events_places.events_feed_group.public_organized_by"
                      )}{" "}
                      <span>{item.user_name}</span>
                    </div>
                  )}

                  {!item.live && (
                    <div className="details__attendees">
                      <div className="attendies__avatars">
                        {item.attendees?.map((item, index) => {
                          const ethAddrs = item?.ethAddress
                          return (
                            <Avatar
                              key={index}
                              address={ethAddrs}
                              size={"small"}
                            />
                          )
                        })}
                      </div>

                      {item.total_attendees !== undefined &&
                        item.total_attendees > 0 && (
                          <div className="Feed__Item__Concurrence">
                            {item.total_attendees}{" "}
                            {l(
                              "component.landing.events_places.events.attendees"
                            )}
                          </div>
                        )}
                    </div>
                  )}
                </div>
                <Button
                  as={Link}
                  href={item.live ? item.url_play : item.url_events}
                  primary={item.live}
                  inverted={!item.live}
                  className={TokenList.join([
                    `Feed__Item__Button`,
                    `event-card__button`,
                    `card__button`,
                    !item.live && `event-card__find-out-more`,
                  ])}
                >
                  {item.live
                    ? l("component.landing.events_places.events.jump_in")
                    : l("component.landing.events_places.events.find_out_more")}
                </Button>
              </div>
            </>
          </Card>
        )
      })}
    </div>
  )
}
