import Time from "decentraland-gatsby/dist/utils/date/Time"
import { memo } from "radash"

import { getHotScenes } from "./hotScenes"

export const getUsersInPosition = memo(
  async (position: string) => {
    const hotScenes = await getHotScenes()
    const hotScene = hotScenes.find((scene) => {
      const positions = new Map(
        scene.parcels.map((parcel) => [parcel.join(","), true])
      )

      return positions.has(position)
    })
    return hotScene?.usersTotalCount || 0
  },
  { ttl: Time.Minute }
)
