import React from "react"

import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"

import priceIcon from "../../../images/2023/marketplace-price-icon.svg"
import { MarketPlaceAttributes } from "./LandingMarketPlaceFeed"
import { formatWeiMANA } from "./mana"

import "./MarketPlaceCard.css"

export const MarketPlaceCard = (props: { item: MarketPlaceAttributes }) => {
  const { item } = props

  const l = useFormatMessage()

  const priceFormatted = formatWeiMANA(item.price)
  const id = item.id.split("-")[0]
  const index = item.id.split("-")[1]
  const uri = `https://market.decentraland.org/contracts/${id}/items/${index}`

  return (
    <a href={uri}>
      <div
        key={item.id}
        className={TokenList.join([
          `marketplace-card`,
          `Feed__Item`,
          `marketplace-card-${item.metadata.wearable.rarity}`,
        ])}
      >
        <div className="marketplace-card__container">
          <div className="marketplace-card__image">
            <img src={item.image} />
          </div>
          <div className="marketplace-card__text">
            <h2 className="marketplace-card__title">
              {item.metadata.wearable.name}
            </h2>
            <div className="marketplace-card__details">
              <h4>{l("component.landing.marketplace.card.polygon")}</h4>
              <div>
                {l("component.landing.marketplace.card.stock")} :{" "}
                {item.available} /{" "}
                <span>{Number(item.available) + Number(item.totalSupply)}</span>
              </div>
            </div>
            <div className="marketplace-card__price">
              <img src={priceIcon} />
              {priceFormatted}
            </div>
            <ul className="marketplace-card__tags">
              <li
                className={TokenList.join([
                  `tag`,
                  `tag-${item.metadata.wearable.rarity}`,
                ])}
              >
                {item.metadata.wearable.rarity}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </a>
  )
}
