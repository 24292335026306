import React from "react"

import avatar_03 from "../../../images/2023/missions/avatar_03.webp"
import car from "../../../images/2023/missions/car.webp"
import emote from "../../../images/2023/missions/emote.webp"
import name from "../../../images/2023/missions/name.webp"
import { MissionsProps } from "./LandingMissions"

const MissionsImageCreate = (props: MissionsProps) => {
  const maskGradient = "create_gradient-" + props.id
  return (
    <svg
      className={"mission__svg mission__svg-create"}
      width="100%"
      height="100%"
      viewBox="0 0 800 800"
    >
      <defs>
        <clipPath id={maskGradient}>
          <path
            d="M710,400c0,171.21-138.79,310-310,310S90,571.21,90,400,228.79,90,400,90s310,138.79,310,310Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="svg__bg">
        <g clipPath={`url(#${maskGradient})`}>
          <image
            id="gradient"
            width="1600"
            height="1600"
            transform="scale(.5)"
            href={props.img_gradient}
          />
        </g>
      </g>
      <g id="svg__shadow">
        <image
          id="shadow"
          width="1600"
          height="1600"
          transform="scale(.5)"
          href={props.img_src_shadow}
        />
      </g>
      <g>
        <image
          id="car"
          width="462"
          height="275"
          transform="translate(301 288)"
          href={car}
        />
      </g>

      <g>
        <image
          id="emote"
          width="287"
          height="515"
          transform="translate(29 271.79) rotate(-4.76) scale(.96)"
          href={emote}
        />
      </g>
      <g>
        <image
          width="642"
          height="777"
          transform="translate(57 15)"
          href={avatar_03}
        />
      </g>
      <g>
        <image
          width="114"
          height="70"
          transform="translate(514 64) scale(1.34)"
          href={name}
        />
      </g>
    </svg>
  )
}

export default MissionsImageCreate
