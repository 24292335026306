import React from "react"

import { Back } from "decentraland-ui/dist/components/Back/Back"

import "./CarouselControlsMobile.css"
export const CarouselControlsMobile = (
  props: any & {
    onPrev: () => void
    onNext: () => void
    activeIndex: number
    total: number
    className?: string
  }
) => {
  const { activeIndex, total, columns } = props

  return (
    <div className={props.className + " carousel-controls"}>
      <span className={"carousel-controls__scrollbar"}>
        <span
          data-index={activeIndex}
          data-total={props.total}
          style={{
            width: (columns / props.total) * 100 + "%",
            transform: `translateX(${(activeIndex % total) * 100}%)`,
          }}
        ></span>
      </span>
      <div>
        <Back
          className={"back__left"}
          onClick={() => {
            props.onPrev()
          }}
        />
        <Back className={"back__right"} onClick={() => props.onNext()} />
      </div>
    </div>
  )
}
