import Time from "decentraland-gatsby/dist/utils/date/Time"
import { memo } from "radash"

import { PlaceAttributes } from "../@types/dcl/places"

export const fetchPlaces = memo(
  async () => {
    const response = await fetch(
      `https://places.decentraland.org/api/places?order_by=most_active`
    )
    const result = await response.json<{
      data: PlaceAttributes[]
      ok: string
    }>()
    return result.data
  },
  { ttl: Time.Minute * 10 }
)
