import React from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import SubTitle from "decentraland-gatsby/dist/components/Text/SubTitle"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import { ContentfulWorldsEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import { SectionViewedTrack } from "../../../modules/segment"

import "./LandingWorlds.css"

export type LandingWorldsProps = {
  worlds: ContentfulWorldsEntriesProps
}

export const LandingWorlds = (props: LandingWorldsProps) => {
  const { worlds } = props
  return (
    <Container fluid className={"worlds"}>
      <SectionTrack sectionViewed={SectionViewedTrack.LandingWorlds} />
      <div className={"worlds__container"}>
        <div className={"worlds__text-container"}>
          <Title>{worlds.title}</Title>

          <SubTitle>{worlds.subtitle}</SubTitle>
          <div className={"worlds__image-container image-container-tablet"}>
            <img src={worlds.imagePortrait.url} />
          </div>
          <p>{worlds.description.text}</p>
          <div className={"buttons-container"}>
            <Button size="large" href={worlds.buttonPrimaryLink}>
              {worlds.buttonPrimaryLabel}
            </Button>
            <Button size="large" inverted href={worlds.buttonSecondaryLink}>
              {worlds.buttonSecondaryLabel}
            </Button>
          </div>
        </div>
        <div className={"worlds__image-container  image-container-desktop"}>
          <img src={worlds.imageLandscape.url} width={318} height={269} />
        </div>
      </div>
    </Container>
  )
}
