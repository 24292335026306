import React from "react"

import Title from "decentraland-gatsby/dist/components/Text/Title"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import Link from "decentraland-gatsby/dist/plugins/intl/Link"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"

import { PlaceAttributes } from "../../@types/dcl/places"
import { Card } from "../Card/Card"
import { Players } from "../Icon/Players"

export const PlacesFeedGroup = (props: { item: PlaceAttributes[] }) => {
  const l = useFormatMessage()
  const group = props.item

  return (
    <div className={TokenList.join(["Feed__List", "places-feed__list"])}>
      {group.map((item, index) => {
        const hrefPlaces =
          "https://places.decentraland.org/place/?position=" +
          item.base_position
        const hrefPlay =
          "https://decentraland.org/play/?position=" + item.base_position

        return (
          <Card
            key={item.id + " : " + index}
            image={item.image}
            href={hrefPlaces}
            className="place-card"
          >
            <>
              <div className="place-card__stats">
                <div className={TokenList.join(["ui", "label", "user-count"])}>
                  <Players noHover />
                  {item.user_count}
                </div>
              </div>

              <div className="places-card__details">
                <div>
                  <Title>{item.title}</Title>
                  {item.contact_name && item.contact_name !== "" && (
                    <div className="feed-item__user-name">
                      {l(
                        "component.landing.events_places.places_feed_group.created_by"
                      )}{" "}
                      <span>{item.contact_name}</span>
                    </div>
                  )}
                </div>
                <Button
                  as={Link}
                  primary
                  className={TokenList.join([
                    "Feed__Item__Button",
                    "card__button",
                  ])}
                  href={hrefPlay}
                >
                  {l("component.landing.events_places.places.jump_in")}
                </Button>
              </div>
            </>
          </Card>
        )
      })}
    </div>
  )
}
