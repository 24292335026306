import React from "react"

import { useInView } from "react-intersection-observer"

import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Hero } from "decentraland-ui/dist/components/Hero/Hero"
import { Mobile, NotMobile } from "decentraland-ui/dist/components/Media/Media"

import {
  isWebpSupported,
  useImageOptimization,
  useVideoOptimization,
} from "../../../hooks/contentful"
import { ContentfulHeroEntryFieldsProps } from "../../../hooks/useLandingContentfulQuery"
import Video from "../../Video/Video"

import "./LandingHeroSlide.css"

export enum LandingHeroSlideAlignment {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export type LandingHeroSlideProps = ContentfulHeroEntryFieldsProps & {
  alignment: LandingHeroSlideAlignment
  index?: number
  children?: React.ReactNode
}

export default React.memo(function LandingHeroSlide(
  props: LandingHeroSlideProps
) {
  const {
    alignment,
    title,
    subtitle,
    buttonPrimaryLabel,
    buttonPrimaryLink,
    buttonSecondaryLabel,
    buttonSecondaryLink,
    imageLandscape,
    videoLandscape,
    imagePortrait,
    videoPortrait,
    id,
    children,
  } = props

  const [sectionInView, isSectionInView] = useInView({ threshold: 0.1 })

  const imageLandscapeOptimized = useImageOptimization(imageLandscape.url)
  const imagePortraitOptimized = useImageOptimization(imagePortrait.url)
  const videoLandscapeOptimized = useVideoOptimization(videoLandscape?.url)
  const videoPortraitOptimized = useVideoOptimization(videoPortrait?.url)

  return (
    <div ref={sectionInView} className="hero-slide__container">
      <Hero
        className={TokenList.join([
          `hero-slide`,
          alignment && `hero-slide-${alignment}`,
        ])}
        data-index={id}
      >
        {/* TODO: user Hero.Header, Hero.Description and Hero.Actions instead this entire hero-slide__text */}
        <div className="hero-slide__text">
          <h1 className="hero-slide__title">{title}</h1>
          <div className="hero-slide__subtitle">{subtitle.text}</div>
          {(children || buttonPrimaryLabel) && (
            <div className="hero-slide__actions">
              {children || (
                <>
                  {buttonPrimaryLabel && (
                    <Button primary size="large" href={buttonPrimaryLink}>
                      {buttonPrimaryLabel}
                    </Button>
                  )}

                  {buttonSecondaryLabel && (
                    <Button inverted size="large" href={buttonSecondaryLink}>
                      {buttonSecondaryLabel}
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <Hero.Content
          className={TokenList.join([
            "hero-content",
            "hero-content__background",
          ])}
        >
          <NotMobile>
            {videoLandscape && (
              <Video
                className="landscape"
                loop
                muted
                autoPlay
                playsInline={true}
                width={videoLandscape.width}
                height={videoLandscape.height}
                poster={
                  (isWebpSupported() && imageLandscapeOptimized.webp) ||
                  imageLandscapeOptimized.jpg ||
                  imageLandscapeOptimized.optimized
                }
                style={{ opacity: isSectionInView ? 1 : 0 }}
                source={videoLandscapeOptimized || videoLandscape.url}
                sourceType={videoLandscape.mimeType}
              />
            )}
            {imageLandscape && (
              <div
                className="hero-content__image-landscape"
                style={{
                  backgroundImage: `url(${
                    (isWebpSupported() && imageLandscapeOptimized.webp) ||
                    imageLandscapeOptimized.jpg ||
                    imageLandscapeOptimized.optimized
                  })`,
                }}
              />
            )}
          </NotMobile>
          <Mobile>
            {videoPortrait && (
              <Video
                className="portrait"
                loop
                muted
                autoPlay
                playsInline={true}
                width={videoPortrait.width}
                height={videoPortrait.height}
                poster={
                  (isWebpSupported() && imagePortraitOptimized.webp) ||
                  imagePortraitOptimized.jpg ||
                  imagePortraitOptimized.optimized
                }
                style={{ opacity: isSectionInView ? 1 : 0 }}
                source={videoPortraitOptimized || videoPortrait.url}
                sourceType={videoPortrait.mimeType}
              />
            )}
            {imagePortrait && (
              <div
                className="hero-content__image-portrait"
                style={{
                  backgroundImage: `url(${
                    (isWebpSupported() && imagePortraitOptimized.webp) ||
                    imagePortraitOptimized.jpg ||
                    imagePortraitOptimized.optimized
                  })`,
                }}
              />
            )}
          </Mobile>
        </Hero.Content>
      </Hero>
    </div>
  )
})
