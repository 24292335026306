import React, { useMemo } from "react"

import Carousel2, {
  IndicatorType,
} from "decentraland-gatsby/dist/components/Carousel2/Carousel2"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { useMobileMediaQuery } from "decentraland-ui/dist/components/Media/Media"
import { cluster } from "radash"

import { fetchPlaces } from "../../modules/places"
import { Card } from "../Card/Card"
import LoadingText from "../Loading/LoadingText"
import { PlacesFeedGroup } from "./PlacesFeedGroup"
import { FeedProps } from "./types"

import "./PlacesFeed.css"

const DEFAULT_COLUMNS = 2

export default React.memo(function PlacesFeed(props: FeedProps) {
  const { columns } = props
  const isMobile = useMobileMediaQuery()
  const [places, state] = useAsyncMemo(fetchPlaces, [])

  const placesGroups = useMemo(() => {
    if (!places || places.length === 0) {
      return []
    }

    if (!isMobile && places.length % 2 === 1) {
      places.pop()
    }

    if (isMobile) {
      return Array.from(cluster(places, 1))
    }
    return Array.from(cluster(places, columns || DEFAULT_COLUMNS))
  }, [places, isMobile, columns])

  if (state.loading) {
    return (
      <div className={TokenList.join(["Feed__List", "events-feed__list"])}>
        <Card className="event-card">
          <LoadingText type="h2" size="full" lines={2} />
        </Card>
        {!isMobile && (
          <Card className="event-card">
            <LoadingText type="h2" size="full" lines={2} />
          </Card>
        )}
      </div>
    )
  }

  return (
    <Carousel2
      time={100000000}
      isFullscreen={true}
      loading={state.loading}
      component={PlacesFeedGroup}
      className={TokenList.join([
        "Feed",
        "places-feed",
        isMobile && "places-feed-mobile",
      ])}
      items={placesGroups}
      indicatorsType={IndicatorType.Dash}
      isNavigationHide={isMobile}
    />
  )
})
