import React from "react"

import avatar_01 from "../../../images/2023/missions/avatar_01.webp"
import avatar_02 from "../../../images/2023/missions/avatar_02.webp"
import gem from "../../../images/2023/missions/gem.webp"
import platforms from "../../../images/2023/missions/platforms.webp"
import { MissionsProps } from "./LandingMissions"

const MissionsImageSell = (props: MissionsProps) => {
  const maskGradient = "sell_gradient-" + props.id
  return (
    <>
      <svg
        className={"mission__svg"}
        width="100%"
        height="100%"
        viewBox="0 0 800 800"
      >
        <defs>
          <clipPath id={maskGradient}>
            <path
              d="M710,400c0,171.21-138.79,310-310,310S90,571.21,90,400,228.79,90,400,90s310,138.79,310,310Z"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="svg__shadow">
          <image
            id="shadow"
            width="1600"
            height="1600"
            transform="scale(.5)"
            href={props.img_src_shadow}
          />
        </g>
        <g id="svg__bg">
          <g clipPath={`url(#${maskGradient})`}>
            <image
              id="gradient"
              width="1600"
              height="1600"
              transform="translate(936.77 578.53) rotate(153.4) scale(.5)"
              href={props.img_gradient}
            />
          </g>
        </g>
        <g id="steps-g">
          <image
            width="660"
            height="415"
            transform="translate(90 349)"
            href={platforms}
          />
        </g>
        <g id="avatar-02-g">
          <image
            width="348"
            height="433"
            transform="translate(90 121)"
            href={avatar_02}
          />
        </g>
        <g id="avatar-g">
          <image
            width="302"
            height="667"
            transform="translate(331 4)"
            href={avatar_01}
          />
        </g>

        <g id="token-g">
          <image
            width="450"
            height="442"
            transform="translate(331 162)"
            href={gem}
          />
        </g>
      </svg>
    </>
  )
}

export default MissionsImageSell
