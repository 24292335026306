import React from "react"

import SectionTrack from "decentraland-gatsby/dist/components/SectionTrack/SectionTrack"
import Title from "decentraland-gatsby/dist/components/Text/Title"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"

import { ContentfulTrendingEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import { SectionViewedTrack } from "../../../modules/segment"

import "./LandingTrending.css"

export type LandingTrendingProps = {
  trendings: ContentfulTrendingEntriesProps
}

export const LandingTrending = (props: LandingTrendingProps) => {
  const [sectionIsVisibile, setSectionIsVisibile] = React.useState(false)

  return (
    <Container
      fluid
      className={TokenList.join([
        "listings",
        sectionIsVisibile && "listings-visible",
      ])}
    >
      <SectionTrack
        sectionViewed={SectionViewedTrack.LandingTrending}
        onInView={() => setSectionIsVisibile(true)}
      />

      {props.trendings.list.map((trending, index) => (
        <div
          className={TokenList.join(["listing", "listings__rentals"])}
          key={index}
        >
          <div
            className="listing__container"
            style={{ backgroundImage: `url(${trending.background.url})` }}
          >
            <img src={trending.image.url} />
            <div className="listing__text">
              <Title>{trending.title}</Title>
              <Button
                className="listing__button"
                inverted
                href={trending.buttonPrimaryLink}
              >
                {trending.buttonPrimaryLabel}
              </Button>
            </div>
          </div>
        </div>
      ))}
    </Container>
  )
}
