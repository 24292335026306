import React, { useMemo } from "react"

import Carousel2, {
  IndicatorType,
} from "decentraland-gatsby/dist/components/Carousel2/Carousel2"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import { Avatar } from "decentraland-gatsby/dist/utils/api/Catalyst.types"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { useMobileMediaQuery } from "decentraland-ui/dist/components/Media/Media"
import { cluster } from "radash"

import { EventAttributes } from "../../@types/dcl/events"
import { fetchEvents } from "../../modules/events"
import { Card } from "../Card/Card"
import LoadingText from "../Loading/LoadingText"
import { EventsFeedGroup } from "./EventsFeedGroup"
import { FeedProps } from "./types"

const DEFAULT_COLUMNS = 4

type AgreggatedEventAttributes = EventAttributes & {
  attendees: (Avatar | null)[]
  user_count: number
}

export type EventFeedItems = {
  id: string
  image: string | null
  total_attendees: number
  attendees: (Avatar | null)[]
  user_count: number
  live: boolean
  date: Date
  url_play: string
  url_events: string
  name: string
  user_name: string | null
}

export default React.memo(function EventsFeed(props: FeedProps) {
  const columns = props.columns || DEFAULT_COLUMNS
  const isMobile = useMobileMediaQuery()
  const [events, state] = useAsyncMemo(fetchEvents, [])

  const eventsGroup: EventFeedItems[][] = useMemo(() => {
    if (!events || events.length === 0) {
      return []
    }

    if (!isMobile && events.length % 2 === 1) {
      events.pop()
    }

    const feedItems = events.map((event: AgreggatedEventAttributes) => ({
      id: event.id,
      image: event.image,
      total_attendees: event.total_attendees,
      attendees: event.attendees || [],
      user_count: event.user_count || 0,
      live: event.live,
      date: event.start_at,
      url_play: event.url,
      url_events: `https://events.decentraland.org/event/?id=${event.id}`,
      name: event.name,
      user_name: event.user_name,
    }))

    if (isMobile) {
      return Array.from(cluster(feedItems, 1))
    }

    return Array.from(cluster(feedItems, columns))
  }, [events, isMobile, columns])

  if (state.loading) {
    return (
      <div className={TokenList.join(["Feed__List", `events-feed__list`])}>
        <Card className="event-card">
          <LoadingText type="h2" size="full" lines={2} />
        </Card>
        {!isMobile && (
          <Card className="event-card">
            <LoadingText type="h2" size="full" lines={2} />
          </Card>
        )}
      </div>
    )
  }

  return (
    <Carousel2
      time={10000000}
      isFullscreen={true}
      items={eventsGroup}
      className={TokenList.join([
        "Feed",
        `events-feed`,
        isMobile && "events-feed-mobile",
      ])}
      component={EventsFeedGroup}
      loop={false}
      indicatorsType={IndicatorType.Dash}
      isNavigationHide={isMobile}
    />
  )
})
