import React from "react"

import hands from "../../../images/2023/missions/hands.webp"
import piramid_01 from "../../../images/2023/missions/piramid_01.webp"
import piramid_02 from "../../../images/2023/missions/piramid_02.webp"
import sun_orange from "../../../images/2023/missions/sun_orange.webp"
import sun_yellow from "../../../images/2023/missions/sun_yellow.webp"
import { MissionsProps } from "./LandingMissions"

const InfluenceSVG = (props: MissionsProps) => {
  const maskGradient = "influence_gradient-" + props.id
  return (
    <svg
      className={"mission__svg"}
      width="100%"
      height="100%"
      viewBox="0 0 800 800"
    >
      <defs>
        <clipPath id={maskGradient}>
          <path
            d="M709.77,399.77c0,171.21-138.79,310-310,310S89.77,570.98,89.77,399.77,228.57,89.77,399.77,89.77s310,138.79,310,310Z"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="svg__bg">
        <g clipPath={`url(#${maskGradient})`}>
          <image
            width="1600"
            height="1600"
            transform="translate(0 -1) scale(.5)"
            href={props.img_gradient}
          />
        </g>
      </g>
      <g id="svg__shadow">
        <image
          id="shadow"
          width="1600"
          height="1600"
          transform="scale(.5)"
          href={props.img_src_shadow}
        />
      </g>
      <g id="hands-g">
        <image
          width="764"
          height="378"
          transform="translate(10 346)"
          href={hands}
        />
      </g>
      <g id="cones-g">
        <image
          width="148"
          height="113"
          transform="translate(173 299)"
          href={piramid_02}
        />
      </g>
      <g id="cones-big-g">
        <image
          width="322"
          height="295"
          transform="translate(360 244) scale(.94)"
          href={piramid_01}
        />
      </g>
      <g id="ball-2-g">
        <image
          width="148"
          height="151"
          transform="translate(425.8 18.51) rotate(7.03)"
          href={sun_orange}
        />
      </g>
      <g id="ball-g">
        <image
          width="72"
          height="72"
          transform="translate(344.44 219.78) rotate(175.49)"
          href={sun_yellow}
        />
      </g>
    </svg>
  )
}

InfluenceSVG.defaultProps = {
  img_src_influence_character: "/playground_assets/influence_character.png",
  img_src_influence_gradient: "/playground_assets/influence_gradient.png",
  img_src_shadow: "/playground_assets/ring_shadow.png",
}

export default InfluenceSVG
